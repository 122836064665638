import React from 'react'

export default function Metrics() {
  return (
    <section class="text-center container mx-auto">
        <div class="text-left">
            <ul class="grid md:grid-cols-3 ">
            <li class="flex items-center">
                <p class="text-3xl md:text-5xl 2xl:text-6xl font-playFair w-1/3 ">100%</p>
                <p class="text-xs md:text-sm uppercase pt-4 pl-4 md:w-20">Client Satisfaction</p>
            </li>

            <li class="flex items-center">
                <p class="text-3xl md:text-5xl 2xl:text-6xl font-playFair w-1/3 ">50+</p>
                <p class="text-xs md:text-sm uppercase pt-4 pl-4 md:w-20">Employees in Dubai</p>
            </li>

            <li class="flex items-center md:w-80">
                <p class="text-3xl md:text-5xl 2xl:text-6xl font-playFair w-1/3 ">3469</p>
                <p class="text-xs md:text-sm uppercase pt-4 pl-4 md:w-64">Projects completed in 60 cities</p>
            </li>

            </ul>
        </div>
    </section>
  )
}
