import React from 'react'
import award from '../images/award.png'
import { StaticImage } from 'gatsby-plugin-image'
import ContactModal from './contactModal'

const awardHero = props => {
    const { cta, awards, full } = props;
    return (
        <section>
            <div class=" mx-auto  md:flex md:items-center md:justify-between">
                {cta || full ?
                    <ContactModal type="cta" />
                    :
                    ""
                }
            </div>
            <StaticImage
                alt="LTD Hero-img"
                className="block w-full h-96 object-cover md:h-auto"
                src='../images/hero-image.png'
            />
        </section>
    )
}

export default awardHero
