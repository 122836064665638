import React from 'react'
import Layout from '../components/layout'
import AwardHero from '../components/awardHero'
import AboutUsSection from '../components/sections/aboutUs'
import Metrics from '../components/metrics'
import banner from '../images/works-1.png'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

export default function AboutPage() {
  return (
    <Layout>
      <SEO title="Live The Dream | About Us| Custom Furniture Company"/>
      <AwardHero awards={true} />
      <AboutUsSection />
      <Metrics />
      <div className='container mx-auto border-t border-tertiary w-11/12 my-20' />
      <ContentBlock 
        blackBG
        eyeBrow="Mission"
        title="Our Mission"
        //subTitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis tempora tempori"
        body="We are enthusiastic about providing excellent service while continually striving to meet the demand for upgrades, updates, and re-invention of ourselves to fit current trends and requirements within our organization. We work hard to provide the highest possible quality and value for our customers. Our main goal is to comprehend their requirements and goals for their individual initiatives, assist them along the way, and demonstrate our value when they encounter problems."
      />
      <ContentBlock 
        eyeBrow="Values"
        title="Our Values"
        subTitle="We have 5 Core Values"
        body={
          <div className='grid gap-8 lg:grid-cols-3'>
              <div className='flex flex-col'> <span className='font-bold'>Trust</span> We build trust through responsible actions and honest relationships to our clients.  </div>
              <div className='flex flex-col'> <span className='font-bold'>Quality</span> We provide high-quality service on time and at a reasonable price, employing the latest in technology and adhering to high ethical standards.</div>
              <div className='flex flex-col'> <span className='font-bold'>Teamwork</span> When we cooperate and work together, we achieve more.</div>
              <div className='flex flex-col'> <span className='font-bold'>Growth</span> Professional development is achieved by the expansion of our talents through creative training.</div>
              <div className='flex flex-col'> <span className='font-bold'>Long-term Perspective</span> We look beyond the present to provide future value.</div>
          </div>
          }
        //body="orem ipsum, dolor sit amet consectetur adipisicing elit. Quia optio, libero id natus iure eos nam laborum eligendi, animi reprehenderit dolorum et, inventore labore qui quasi vitae! Temporibus, blanditiis earum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis impedit, fuga praesentium consequuntur voluptas, eius aliquid quaerat sapiente, odio vero aspernatur mollitia pariatur? Doloremque itaque eum earum velit nostrum doloribus"
      />
      <ContentBlock 
        blackBG
        eyeBrow="Vision"
        title="Our Vision"
        //subTitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis tempora tempori"
        body="We are enthusiastic about providing excellent service while continually striving to meet the demand for upgrades, updates, and re-invention of ourselves to fit current trends and requirements within our organization. We work hard to provide the highest possible quality and value for our customers. Our main goal is to comprehend their requirements and goals for their individual initiatives, assist them along the way, and demonstrate our value when they encounter problems."
        image={banner}
      />

    </Layout>
  )
}
