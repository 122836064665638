import React from 'react'

const EyebrowHeader = props => {
    const { text, left, right } = props;
  return (
    <h3
        className={
          `${ left ? "eyebrow-left " : " "}` 
          + `${right ? "eyebrow-right " : " "}`
          + " uppercase text-primary text-xs md:text-base"}
      >{text}
    </h3>
  )
}

export default EyebrowHeader