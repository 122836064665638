import React from 'react'
import aboutBadge from '../../images/aboutBadge.png'
import EyebrowHeader from '../eyebrowHeader'
import { StaticImage } from 'gatsby-plugin-image'

export default function aboutUsSection() {
  return (

	<div className="py-16 container grid md:grid-cols-2 mx-auto lg:flex-row lg:justify-between ">
		<div className="md:pt-12">
			<EyebrowHeader left text="About" />
			<h2 className="text-3xl md:text-5xl 2xl:text-6xl font-playFair py-4">
				We help to bring your <em>solution</em> to reality
			</h2>
			<p className="mt-6 mb-8 md:text-lg px-2">We are an Interior fit out and furniture manufacturing company producing bespoke retail, home and office furniture with a team of 60+ master craftsmen specialized in design, build & execution in a fabrication facility spread over 50,000 sqft. located in DIP 1 Dubai, UAE. With a commitment to quality at affordable prices, we promise timely delivery, flawless installation and diligent after sale service. We are here to  continuously support your patronage. We hope to develop a new and lasting relationship with you</p>
			<p className="mt-6 mb-8 md:text-lg px-2">Our facility which is equipped with Laser cutting machines, CNC routers, large format printers and plotters can produce works of art with all sorts of materials like wood, metal, acrylic, leather etc. We also undertake fit out contracts including sourcing authority approvals. The expert team & designers work round the clock with leading clients & international brands across the globe. We are committed to working together as partners & provide the best possible solutions aiming for unmatchable quality results.</p>

		</div>
		<div className="justify-center w-full mt-8 lg:mt-0 relative">
			<StaticImage src='../../images/aboutBadge.png' alt="About Live The Dream" className="w-full" />
		</div>
</div>
  )
}
